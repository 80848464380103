<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="1"/>
        <b-col cols="10" style="font-size: 14px">
          <SubHeader class="mb-2">
            <b-row class="mt-2 mb-1">
              <b-col cols="3">
                <b-form-input v-model="search" placeholder="Suche nach Templates oder Kursen..." size="sm" debounce="500"/>
              </b-col>
            </b-row>
          </SubHeader>
          <TemplateTable v-if="templatesFiltered" :tableItems="templatesFiltered"/>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import TemplateTable from "@/components/lists/templateList/TemplateTable";
import _ from "lodash";

export default {
  name: "TemplateList",
  components: {
    SubHeader,
    ScrollToTop,
    TemplateTable
  },
  data () {
    return {
      search: '',
      templateList: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getTemplateList() {
      await getRequest('templateList/all', null, this)
          .then((response) => {
            this.templateList = response.data
          })
    }
  },
  computed: {
    templatesFiltered() {
      return _.filter(this.templateList, (entry) => {
          return entry.displayname.toLowerCase().includes(this.search.toLowerCase()) ||
                 entry.courses.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  async mounted() {
    this.setLoadingState(true);
    await this.getTemplateList();
    this.setLoadingState(false);
  }
}
</script>

<style lang="scss" scoped>
</style>
